import hash from 'string-hash';

const isBrowser = () => typeof window !== "undefined";

export const getDeviceToken = () => {
  if (!isBrowser()) return '';

  const tokenObject = {
    userAgent: window.navigator.userAgent,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      pixelDepth: window.screen.colorDepth,
      colorDepth: window.screen.pixelDepth
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  return hash(JSON.stringify(tokenObject));
};